<template>
    <div class="container px-1 py-5" dusk="worksheet-show" v-if="worksheet">
        <div class="flex flex-col justify-between mb-5 md:flex-row md:mb-10">
            <h1 class="lg:text-3xl mb-5 md:mb-0 text-2xl">{{ worksheet.ticket.title }}</h1>

            <div class="flex items-center shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" v-on:click="router.back()">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success mr-2 text-white" dusk="save-worksheet-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
                <a class="bg-highlight cursor-pointer disabled:cursor-not-allowed disabled:opacity-30 duration-300 ease-out font-bold inline-flex items-center justify-center lg:min-w-[200px] p-2.5 rounded text-xs text-white transition-all uppercase" target="_blank"
                   v-bind:href="'/tenants/' + sessionStore.user.tenant.id + '/worksheets/' + worksheet.id + '.pdf' + '?v=' + Math.ceil(Math.random() * 1e16)"
                   v-if="worksheet.signed_at"
                >
                    <c-icon class="stroke-white h-4 mr-2 w-4" icon="download-cloud" />
                    {{ __('Download PDF worksheet') }}
                </a>
            </div>
        </div>
        <div class="flex flex-col-reverse lg:flex-row items-start">
            <div class="flex flex-col lg:mr-4 lg:w-2/3 w-full">
                <div class="bg-white p-2 rounded shadow-sm">
                    <h2 class="font-medium text-lg">{{ __('Travel distance') }}</h2>
                    <div class="flex items-center">
                        <c-control-text class="mr-2 w-full" name="worksheet.travel_distance"
                                        v-model="worksheet.travel_distance"
                        />
                        <span class="text-sm">{{ __('units.distance') }}</span>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <div class="flex items-center justify-between mb-5">
                        <h2 class="font-medium text-lg">{{ __('Time worked') }}</h2>
                        <c-button class="bg-slate-100 group hover:bg-highlight hover:text-white py-2 text-highlight"
                                  router
                                  v-bind:to="{ name : 'worksheets.time', params : { worksheet : worksheet.id } }"
                        >
                            <c-icon class="group-hover:stroke-white h-5 mr-2 stroke-highlight w-5" icon="edit-3" />
                            {{ __('Edit time logs') }}
                        </c-button>
                    </div>

                    <div class="gap-4 grid grid-cols-12 items-center mb-2" v-for="log in worksheet.time_logs_grouped">
                        <div class="col-span-4">
                            <img class="h-6 inline mr-2 -mt-1 rounded w-6"
                                 v-bind:src="log.assignee.avatar"
                                 v-bind:alt="log.assignee.name"
                            />
                            <span class="text-sm">{{ log.assignee.name }}</span>
                        </div>
                        <span class="col-span-8">{{ log.time_for_humans }}</span>
                    </div>

                    <strong class="block mt-5 text-sm">{{ __('Total') }}</strong>
                    <div>
                        <span class="block font-black text-2xl">{{ worksheet.time_for_humans }}</span>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Scheduling') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Start time') }}</strong>
                    <div class="flex items-center text-sm">
                        <div class="flex items-center" v-if="!showEditCreatedAt">
                            {{
                                DateTime.fromISO(
                                    worksheet.created_at,
                                    { locale : localizationStore.locale }
                                ).toFormat(localizationStore.dateFormat)
                            }}
                            {{ DateTime.fromISO(worksheet.created_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            <c-icon class="cursor-pointer h-4 inline ml-2 stroke-highlight w-4" icon="edit-3"
                                    v-on:click="showEditCreatedAt = true"
                            />
                        </div>
                        <div class="flex items-center justify-between"
                             v-if="showEditCreatedAt"
                        >
                            <c-control-date class="mr-4 w-1/2"
                                            icon="calendar"
                                            icon-position="left"
                                            name="worksheet.created_at_date"
                                            v-bind:enable-time-picker="false"
                                            v-bind:format="localizationStore.dateFormat"
                                            v-bind:invalid="!!requestStore.errors?.created_at"
                                            v-model="worksheet.created_at_date"
                            />

                            <c-control-date class="w-1/2"
                                            icon="clock"
                                            icon-position="left"
                                            minutes-increment="5"
                                            name="worksheet.created_at_time"
                                            time-picker
                                            v-bind:invalid="!!requestStore.errors?.created_at"
                                            v-bind:start-time="{ hours: 8, minutes: 0 }"
                                            v-model="worksheet.created_at_time"
                            />
                            <c-button class="bg-success ml-4 !p-1.5"
                                      v-on:click="updateWorksheetTimes(); showEditCreatedAt = false;"
                            >
                                <c-icon class="cursor-pointer h-5 inline stroke-white w-5" icon="check" />
                            </c-button>
                        </div>

                        <span class="inline-flex items-center ml-5 text-xs"
                              v-if="worksheet.ticket.schedule_start_date"
                        >
                            <span class="relative"
                                  v-on:mouseover="tooltipStartDate = true"
                                  v-on:mouseout="tooltipStartDate = false"
                            >
                                <div v-if="DateTime.fromISO(worksheet.ticket.schedule_start_date + 'T' + (worksheet.ticket.schedule_start_time ?? '00:00')).diff(DateTime.fromISO(worksheet.created_at), 'hours').toObject().hours > 1"
                                >
                                    <c-icon class="cursor-help h-6 mr-2 stroke-warning w-6" icon="alert-triangle" />
                                    <c-tooltip position="bottom" v-bind:active="tooltipStartDate">
                                        {{ __('Delay over :duration hours.', { duration: 1 }) }}
                                    </c-tooltip>
                                </div>
                                <c-icon class="h-6 mr-2 stroke-success w-6" icon="check-circle"
                                        v-else
                                />
                            </span>

                            {{ __('Scheduled') }}:&nbsp;
                            <span v-if="worksheet.ticket.schedule_start_date">
                                {{
                                    DateTime.fromISO(
                                        worksheet.ticket.schedule_start_date,
                                        { locale : localizationStore.locale }
                                    ).toFormat(localizationStore.dateFormat)
                                }}&nbsp;
                            </span>
                            <span v-if="worksheet.ticket.schedule_start_time">
                                {{ DateTime.fromISO(worksheet.ticket.schedule_start_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            </span>
                        </span>
                    </div>

                    <strong class="block mb-1 mt-5 text-sm" >{{ __('End time') }}</strong>
                    <div class="flex items-center text-sm">
                        <div class="flex items-center" v-if="!showEditSignedAt">
                            <span v-if="worksheet.signed_at">
                                {{
                                    DateTime.fromISO(
                                        worksheet.signed_at,
                                        { locale : localizationStore.locale }
                                    ).toFormat(localizationStore.dateFormat)
                                }}
                                {{ DateTime.fromISO(worksheet.signed_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            </span>
                            <span v-else>n/a</span>
                            <a class="relative"
                               v-on:click="worksheet.ticket.assignee ? showEditSignedAt = true : null"
                               v-on:mouseover="tooltipSignedAt = true"
                               v-on:mouseout="tooltipSignedAt = false"
                            >
                                <c-icon class="cursor-pointer h-4 inline ml-2 stroke-highlight w-4" icon="edit-3"
                                        v-bind:class="{ 'stroke-slate-400' : !worksheet.ticket.assignee }"
                                />
                                <c-tooltip class="ml-2" position="right"
                                           v-bind:active="tooltipSignedAt"
                                           v-if="!worksheet.ticket.assignee"
                                >
                                    {{ __('End time can only be specified when assignee is set.') }}
                                </c-tooltip>
                            </a>
                        </div>
                        <div class="flex items-center justify-between"
                             v-if="showEditSignedAt"
                        >
                            <c-control-date class="mr-4 w-1/2"
                                            icon="calendar"
                                            icon-position="left"
                                            name="worksheet.signed_at_date"
                                            v-bind:enable-time-picker="false"
                                            v-bind:format="localizationStore.dateFormat"
                                            v-bind:invalid="!!requestStore.errors?.signed_at"
                                            v-model="worksheet.signed_at_date"
                            />

                            <c-control-date class="w-1/2"
                                            icon="clock"
                                            icon-position="left"
                                            minutes-increment="5"
                                            name="worksheet.signed_at_time"
                                            time-picker
                                            v-bind:invalid="!!requestStore.errors?.signed_at"
                                            v-bind:start-time="{ hours: 8, minutes: 0 }"
                                            v-model="worksheet.signed_at_time"
                            />

                            <c-button class="bg-success ml-4 !p-1.5"
                                      v-on:click="updateWorksheetTimes(); showEditSignedAt = false;"
                            >
                                <c-icon class="cursor-pointer h-5 inline stroke-white w-5" icon="check" />
                            </c-button>
                        </div>

                        <span class="inline-flex items-center ml-5 text-xs"
                              v-if="worksheet.ticket.schedule_end_date"
                        >
                            <span class="relative"
                                  v-on:mouseover="tooltipEndDate = true"
                                  v-on:mouseout="tooltipEndDate = false"
                            >
                                <div v-if="DateTime.fromISO(worksheet.ticket.schedule_end_date + 'T' + (worksheet.ticket.schedule_end_time ?? '00:00')).diff(DateTime.fromISO(worksheet.signed_at), 'hours').toObject().hours > 1"
                                >
                                    <c-icon class="cursor-help h-6 mr-2 stroke-warning w-6" icon="alert-triangle"/>
                                    <c-tooltip position="bottom" v-bind:active="tooltipEndDate">
                                        {{ __('Delay over :duration hours.', { duration: 1 }) }}
                                    </c-tooltip>
                                </div>
                                <c-icon class="h-6 mr-2 stroke-success w-6" icon="check-circle"
                                        v-else
                                />
                            </span>

                            {{ __('Scheduled') }}:&nbsp;
                            <span v-if="worksheet.ticket.schedule_end_date">
                                {{
                                    DateTime.fromISO(
                                        worksheet.ticket.schedule_end_date,
                                        { locale : localizationStore.locale }
                                    ).toFormat(localizationStore.dateFormat)
                                }}&nbsp;
                            </span>
                            <span v-if="worksheet.ticket.schedule_end_time">
                                {{ DateTime.fromISO(worksheet.ticket.schedule_end_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            </span>
                        </span>
                    </div>

                    <strong class="block mt-5 text-sm" v-if="worksheet.ticket.next_occurrence">{{ __('Recurring?') }}</strong>
                    <div class="text-sm" v-if="worksheet.ticket.next_occurrence">
                        {{ __('Every') }}
                        {{ worksheet.ticket.recurrence_interval }}
                        {{ frequencies.find((f) => f.value === worksheet.ticket.recurrence_frequency)?.label }}.<br />

                        {{ __('Next occurrence') }}:
                        {{
                            DateTime.fromISO(
                                worksheet.ticket.next_occurrence,
                                { locale : localizationStore.locale }
                            ).toFormat(localizationStore.dateFormat)
                        }}
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Assignees') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Primary assignee') }}</strong>
                    <c-control-autocomplete class="mb-5" dusk="assignee-field"
                                            avatar-field="avatar" label-field="name" name="worksheet.ticket.assignee"
                                            v-bind:invalid="!!requestStore.errors?.assignee_id"
                                            v-bind:placeholder="__('Search by employee name')"
                                            v-bind:source-get="employeeGet"
                                            v-bind:source-search="employeeSearch"
                                            v-if="worksheet.signed_at === null"
                                            v-model="worksheet.ticket.assignee"
                    />
                    <span class="flex mb-5 items-center" v-else>
                        <img class="h-6 mr-2 rounded" v-bind:src="worksheet.ticket.assignee.avatar" v-bind:alt="worksheet.ticket.assignee.name" />
                        <span class="text-sm">{{ worksheet.ticket.assignee.name }}</span>
                    </span>

                    <strong class="block mb-1 text-sm">{{ __('Additional assignees') }}</strong>
                    <c-control-tag avatar-field="avatar" label-field="name" name="worksheet.ticket.additional_assignees"
                                   v-bind:invalid="!!requestStore.errors?.additional_assignees"
                                   v-bind:placeholder="__('Search by employee name')"
                                   v-bind:source-get="employeeGet"
                                   v-bind:source-search="employeeSearch"
                                   v-model="worksheet.ticket.additional_assignees"
                    />
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <div class="flex items-center justify-between mb-5">
                        <h2 class="font-medium text-lg">{{ __('Uploaded files') }}</h2>
                        <c-button class="bg-slate-100 group hover:bg-highlight hover:text-white py-2 text-highlight"
                                  v-on:click="downloadFiles"
                        >
                            <c-icon class="group-hover:stroke-white h-5 mr-2 stroke-highlight w-5" icon="download-cloud" />
                            {{ __('Download all') }}
                        </c-button>
                    </div>

                    <div class="grid grid-cols-3 gap-4 mb-5 md:grid-cols-6" dusk="files-container">
                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                           v-bind:href="file.url"
                           v-for="(file, key) in worksheet.files"
                        >
                            <img class="max-h-32 rounded"
                                 v-bind:alt="file.name"
                                 v-bind:src="file.url"
                                 v-if="isImage(file.name)"
                            />
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                    v-else
                            />

                            <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file.name }}
                            </span>

                            <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error" dusk="delete-file-button"
                                  v-on:click.prevent="deleteFile(key)"
                            >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                        </a>

                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                           v-bind:href="newFiles[key]"
                           v-for="(file, key) in newFilenames"
                        >
                            <img class="max-h-32 rounded"
                                 v-bind:alt="file"
                                 v-bind:src="newFiles[key]"
                                 v-if="isImage(file)"
                            />
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                    v-else
                            />

                            <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file }}
                            </span>

                            <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error"
                                  v-on:click.prevent="deleteFile(key, true)"
                            >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                        </a>

                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex group hover:border-slate-500 items-center justify-center p-2 rounded transition-all"
                           v-on:click="upload"
                        >
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                        </a>
                    </div>
                    <c-control-file class="h-0 opacity-0 pointer-events-none w-0" multiple name="worksheet.files" v-bind:filenames="newFilenames" v-model="newFiles" />
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Devices') }}</h2>

                    <ul v-if="worksheet.serial_numbers.length > 0">
                        <li class="border-b border-slate-200 flex group items-center last:border-b-0 last:mb-0 last:pb-0 mb-5 md:border-b-0 md:mb-1 md:pb-0 pb-5 text-sm w-full"
                            v-bind:class="{ 'cursor-pointer hover:text-error' : sn.is_custom }"
                            v-for="(sn, key) in worksheet.serial_numbers"
                        >
                            <div class="cursor-pointer duration-500 flex flex-col group hover:bg-slate-100 justify-between md:flex-row md:items-center p-1 relative rounded text-slate-700 text-sm transition-all w-[calc(100%-2rem)]">
                                <div class="flex items-center md:w-1/3">
                                    <input class="h-4 mr-2 opacity-0 peer relative w-4 z-20" name="task.completed_at" type="checkbox"
                                           v-bind:checked="sn.has_been_serviced"
                                           v-on:change="sn.has_been_serviced = !sn.has_been_serviced"
                                    />

                                    <span class="absolute before:absolute before:content-['&#x2714;'] before:flex before:font-['Zapf_Dingbats'] before:h-full before:items-center before:justify-center before:left-0 before:text-white before:text-xs before:top-0 before:w-full bg-white border-2 border-slate-500 duration-500 h-4 left-1 peer-checked:bg-highlight peer-checked:border-highlight rounded transition-all w-4 z-10" />

                                    <div class="flex flex-col">
                                        <span v-if="sn.serial_number.part_number.make || sn.serial_number.part_number.model">
                                            {{ sn.serial_number.part_number.make }} {{ sn.serial_number.part_number.model }}
                                        </span>
                                        <span class="text-slate-500 text-xs">{{ sn.sn }}</span>
                                    </div>
                                </div>

                                <label class="flex items-center md:mt-0 md:w-1/3 mt-5">
                                    {{ __('Usage') }}
                                    <c-control-number class="mx-2 w-16" name="usage"
                                                      v-bind:min="0"
                                                      v-model="sn.usage"
                                    />
                                    {{ __('hours') }}
                                </label>

                                <label class="flex items-center md:ml-10 md:mt-0 md:w-1/3 mt-5 shrink-0">
                                    <c-control-toggle class="mr-2" name="maintenance_performed"
                                                      v-model="sn.maintenance_performed"
                                    />
                                    {{ __('Maintenance performed?') }}
                                </label>
                            </div>
                            <a class="cursor-pointer duration-500 group-hover:block group/icon hidden hover:bg-error ml-auto p-1 rounded text-sm text-error transition-colors"
                               v-if="sn.is_custom"
                               v-on:click="deleteSn(key)"
                            >
                                <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                            </a>
                        </li>
                    </ul>

                    <div class="flex items-center mt-4 w-full">
                        <c-control-autocomplete class="mr-5 w-full" name="addedSn"
                                                v-bind:label-field="(data) => {
                                                    let label = data.part_number.make || data.part_number.model
                                                        ? '(' + data.sn + ')'
                                                        : data.sn;

                                                    if (data.part_number.model) {
                                                         label = data.part_number.model + ' ' + label;
                                                    }

                                                    if (data.part_number.make) {
                                                         label = data.part_number.make + ' ' + label;
                                                    }

                                                   return label;
                                                }"
                                                v-bind:placeholder="__('Search by name or S/N')"
                                                v-bind:source-get="snGet"
                                                v-bind:source-search="snSearch"
                                                v-model="addedSn"
                        />
                        <c-button class="bg-highlight"
                                  v-bind:class="{ 'bg-slate-800' : !addedSn }"
                                  v-bind:disabled="!addedSn"
                                  v-on:click="addSn"
                        >
                            <c-icon class="h-3 stroke-white w-3" icon="corner-down-left" />
                        </c-button>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Tasks') }}</h2>

                    <ul v-if="worksheet.tasks.length > 0">
                        <li class="flex group items-center mb-1 text-sm w-full"
                            v-bind:class="{ 'cursor-pointer hover:text-error' : task.is_custom }"
                            v-for="(task, key) in worksheet.tasks"
                        >
                            <label class="cursor-pointer duration-500 flex group hover:bg-slate-100 items-center p-1 relative rounded text-slate-700 text-sm transition-all w-auto">
                                <input class="h-4 mr-2 opacity-0 peer relative w-4 z-20" name="task.completed_at" type="checkbox"
                                       v-bind:checked="task.completed_at"
                                       v-on:change="task.completed_at = task.completed_at ? null : DateTime.now().toISO()"
                                />

                                <span class="absolute before:absolute before:content-['&#x2714;'] before:flex before:font-['Zapf_Dingbats'] before:h-full before:items-center before:justify-center before:left-0 before:text-white before:text-xs before:top-0 before:w-full bg-white border-2 border-slate-500 duration-500 h-4 left-1 peer-checked:bg-highlight peer-checked:border-highlight rounded transition-all w-4 z-10" />

                                <div class="relative">
                                    {{ task.name }}

                                    <div class="absolute after:absolute after:border-8 after:border-b-slate-700 after:border-transparent after:bottom-full after:content-[''] after:h-0 after:pointer-events-none after:left-4 after:w-0 bg-slate-700 -bottom-2 group-hover:block hidden p-1.5 md:after:border-b-transparent md:after:border-r-slate-700 md:after:bottom-auto md:after:left-auto md: after:right-full md:after:top-1/2 md:after:-translate-y-1/2 md:bottom-auto md:-right-4 md:top-1/2 md:translate-x-full md:-translate-y-1/2 rounded text-white translate-y-full w-[350px] z-10"
                                         v-if="(task.notes || (task.files && task.files.length > 0))"
                                    >
                                        <strong class="block mb-1 text-xs" v-if="task.notes">{{ __('Notes') }}</strong>
                                        <span class="text-xs" v-if="task.notes">{{ task.notes }}</span>

                                        <div v-bind:class="{ 'mt-5' : task.notes }"
                                             v-if="task.files && task.files.length > 0"
                                        >
                                            <div class="flex items-center justify-between">
                                                <strong class="block mb-1 text-xs">{{ __('Files') }}</strong>

                                                <c-button class="bg-indigo-500 group hover:bg-highlight hover:text-white py-1 text-white text-xs"
                                                          v-on:click="downloadTaskFiles(task.id)"
                                                >
                                                    <c-icon class="group-hover:stroke-white h-4 mr-2 stroke-highlight w-4" icon="download-cloud" />
                                                    {{ __('Download all') }}
                                                </c-button>
                                            </div>
                                            <ul class="mt-2">
                                                <li class="last:mb-0 mb-1" v-for="file in task.files">
                                                    <a class="flex items-center text-xs text-white" target="_blank"
                                                       v-bind:href="file.url"
                                                    >
                                                        <c-icon class="h-4 mr-2 stroke-white w-4" icon="file" />

                                                        {{ file.name }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <a class="cursor-pointer duration-500 group-hover:block group/icon hidden hover:bg-error ml-auto p-1 rounded text-sm text-error transition-colors" dusk="delete-task-button"
                               v-if="task.is_custom"
                               v-on:click="deleteTask(key)"
                            >
                                <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                            </a>
                        </li>
                    </ul>
                    <div class="flex items-center mt-4 w-full">
                        <c-control-text class="mr-2 w-full" name="addedTask"
                                        v-bind:placeholder="__('Task description')"
                                        v-model="addedTask"
                                        v-on:keyup.enter="addTask"
                        />
                        <c-button class="bg-highlight" dusk="add-task-button"
                                  v-bind:class="{ 'bg-slate-800' : addedTask === '' }"
                                  v-bind:disabled="addedTask === ''"
                                  v-on:click="addTask"
                        >
                            <c-icon class="h-3 stroke-white w-3" icon="corner-down-left" />
                        </c-button>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Products used') }}</h2>
                    <ul v-if="worksheet.inventory.length > 0">
                        <li class="cursor-pointer grid grid-cols-12 group items-center mb-2 text-sm w-full"
                            v-for="(product, key) in worksheet.inventory"
                        >
                            <div class="col-span-4 flex items-center md:col-span-2 sm:col-span-3">
                                <span class="cursor-help relative"
                                      v-if="product.is_custom"
                                      v-on:mouseover="tooltipInventory = product.id"
                                      v-on:mouseout="tooltipInventory = null"
                                >
                                    <c-icon class="h-6 mr-2 stroke-warning w-6" icon="alert-triangle" />
                                    <c-tooltip class="ml-2" position="right"
                                               v-bind:active="tooltipInventory === product.id"
                                               v-if="product.is_custom"
                                    >
                                        {{ __('Custom product.') }}
                                    </c-tooltip>
                                </span>
                                <c-icon class="h-6 mr-2 stroke-slate-500 w-6" icon="package" v-else />

                                <c-control-number class="w-16" name="product.quantity"
                                                  v-bind:invalid="!!requestStore.errors?.['inventory.' + key + '.quantity']"
                                                  v-bind:min="1"
                                                  v-model="product.quantity"
                                                  v-on:click.stop
                                />
                                <span class="ml-1 mr-2 w-10">{{ __('units.' + product.um) }}</span>
                            </div>
                            <div class="col-span-7 md:col-span-5 sm:col-span-4">
                                <span>{{ product.name }}</span>
                                <span class="ml-2" v-if="product.code">({{ product.code }})</span>
                            </div>

                            <div class="col-span-4 hidden items-center sm:flex">
                                <c-control-number class="mr-4 w-32" name="product.cost_price"
                                                  v-bind:invalid="!!requestStore.errors?.['inventory.' + key + '.cost_price']"
                                                  v-bind:min="0"
                                                  v-bind:placeholder="__('Cost price')"
                                                  v-model="product.cost_price"
                                                  v-on:click.stop
                                />

                                <c-control-number class="w-32" name="product.sale_price"
                                                  v-bind:invalid="!!requestStore.errors?.['inventory.' + key + '.sale_price']"
                                                  v-bind:min="0"
                                                  v-bind:placeholder="__('Sale price')"
                                                  v-model="product.sale_price"
                                                  v-on:click.stop
                                />
                            </div>

                            <div class="col-span-1 ml-auto">
                                <a class="cursor-pointer duration-500 group/icon group-hover:block hidden hover:bg-error p-1 rounded text-sm text-error transition-colors"
                                   dusk="delete-product-button"
                                   v-on:click="deleteProduct(key)"
                                >
                                    <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                                </a>
                            </div>
                        </li>
                    </ul>

                    <div class="flex items-center mt-4 w-full">
                        <c-control-autocomplete class="mr-2 w-full" name="added-product"
                                                v-bind:label-field="(data) => {
                                                    let label = data.name

                                                    if (data.code) {
                                                         label += ' (' + data.code + ')';
                                                    }

                                                   return label;
                                                }"
                                                v-bind:invalid="addedProductInvalid"
                                                v-bind:placeholder="__('Search by name or article number')"
                                                v-bind:source-get="productGet"
                                                v-bind:source-search="productSearch"
                                                v-model="addedProduct"
                                                v-on:change="addedProductInvalid = false"
                                                v-on:keyup.enter="addProduct"
                        />
                        <c-button class="bg-highlight" dusk="add-product-button"
                                  v-bind:class="{ 'bg-slate-800' : !addedProduct || addedProduct === '' }"
                                  v-bind:disabled="!addedProduct || addedProduct === ''"
                                  v-on:click="addProduct"
                        >
                            <c-icon class="h-3 stroke-white w-3" icon="corner-down-left" />
                        </c-button>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="fields.length > 0 && worksheet.fieldValues">
                    <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

                    <template v-for="field in fields">
                        <div class="mt-5">
                            <div class="bg-indigo-50 group mb-1 p-2 rounded"
                                 v-for="repetition in computeFieldRepetitions(field)"
                                 v-if="field.fields && field.fields.length > 0"
                            >
                                <div class="flex items-center justify-between mb-4">
                                    <strong class="text-sm">{{ field.label }}</strong>
                                    <a class="cursor-pointer duration-500 group-hover:pointer-events-auto group-hover:visible group/icon hover:bg-error invisible p-1 pointer-events-none rounded transition-colors"
                                       v-if="repetition > 1"
                                       v-on:click="deleteFieldRepetition(field, repetition)"
                                    >
                                        <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                                    </a>
                                </div>
                                <div class="mb-5"
                                     v-for="subfield in field.fields"
                                >
                                    <custom-field v-bind:field="subfield"
                                                  v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                                  v-model="worksheet.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                                    />
                                </div>
                                <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                                   v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                                   v-on:click="addFieldRepetition(field)"
                                >
                                    <c-icon class="h-6 stroke-white w-6" icon="plus" />
                                </a>
                            </div>
                            <custom-field v-bind:field="field"
                                          v-bind:id="field.key"
                                          v-else
                                          v-model="worksheet.fieldValues[field.key]"
                            />
                        </div>
                    </template>
                </div>
            </div>

            <div class="bg-white flex flex-col lg:mb-0 lg:w-1/3 mb-4 p-2 rounded shadow-sm w-full">
                <div class="flex items-center justify-between mb-5 w-full">
                    <div class="flex items-center">
                        <span class="mr-2 text-slate-500 text-xs">#{{ worksheet.ticket.oid }}</span>
                        <span class="text-sm">{{ worksheet.ticket.title }}</span>
                        <span class="bg-yellow-400 cursor-help ml-2 p-1 relative rounded"
                              v-if="!worksheet.signed_at"
                        >
                            <c-icon class="h-4 fill-white w-4" icon="tool" />
                        </span>
                        <span class="bg-success cursor-help ml-2 p-1 relative rounded"
                              v-else
                        >
                            <c-icon class="h-4 fill-white w-4" icon="tool" />
                        </span>
                        <span class="font-bold ml-1 text-sm text-success"
                              v-if="worksheet.oid"
                        >
                            #{{ worksheet.oid }}
                        </span>
                    </div>
                    <span class="ml-4 shrink-0 text-slate-500 text-xs">
                        {{
                            DateTime.fromISO(
                                worksheet.ticket.updated_at,
                                { locale : localizationStore.locale }
                            ).toRelative()
                        }}
                    </span>
                </div>
                <strong class="mb-1 text-sm">{{ __('Partner') }}</strong>
                <span class="text-sm">{{ worksheet.ticket.site?.partner.name }}</span>

                <strong class="mb-1 mt-5 text-sm">{{ __('Site') }}</strong>
                <div class="text-sm">
                    <span class="block">{{ worksheet.ticket.site?.address_line_1 }}</span>
                    <span class="block" v-if="worksheet.ticket.site?.address_line_2">{{ worksheet.ticket.site.address_line_2 }}</span>
                    <span class="block">
                        <span v-if="worksheet.ticket.site?.postcode">{{ worksheet.ticket.site.postcode }} </span>
                        {{ worksheet.ticket.site?.city }},
                        {{ worksheet.ticket.site?.country }}
                    </span>
                </div>

                <strong class="mb-1 mt-5 text-sm">{{ __('Ticket description') }}</strong>
                <div class="text-sm">{{ worksheet.ticket.description }}</div>

                <strong class="mb-1 mt-5 text-sm" v-if="worksheet.ticket.notes">{{ __('Notes') }}</strong>
                <div class="text-sm" v-if="worksheet.ticket.notes">{{ worksheet.ticket.notes }}</div>

                <strong class="mb-1 mt-5 text-sm">{{ __('Billable?') }}</strong>
                <span class="text-sm">{{ worksheet.is_billable ? __('Yes') : __('No') }}</span>

                <strong class="mb-1 mt-5 text-sm" v-if="worksheet.ticket.files.length > 0">{{ __('Files') }}</strong>
                <div class="grid grid-cols-3 gap-4">
                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col hover:border-slate-500 items-center justify-center p-2 rounded transition-all" target="_blank"
                       v-bind:href="file.url"
                       v-for="file in worksheet.ticket.files"
                       v-if="worksheet.ticket.files.length > 0"
                    >
                        <img class="max-h-32 mr-2 rounded"
                             v-bind:alt="file.name"
                             v-bind:src="file.url"
                             v-if="isImage(file.name)"
                        />
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                v-else
                        />
                        <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                            {{ file.name }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, isRef, onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlFile,
        CControlNumber,
        CControlTag,
        CControlText,
        CControlToggle,
        CIcon,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import CustomField from '../../components/CustomField.vue';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import InventoryRepository from '../../repositories/InventoryRepository';
    import SerialNumberRepository from '../../repositories/SerialNumberRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import WorksheetRepository from '../../repositories/WorksheetRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');
    const addedProduct = ref<object>();
    const addedProductInvalid = ref<boolean>(false);
    const addedSn = ref<object>();
    const addedTask = ref<string>('');
    const employeeRepository = EmployeeRepository.getInstance();
    const inventoryRepository = InventoryRepository.getInstance();
    const fields = ref<Object[]>([]);

    const frequencies = [
        {
            value: 'days',
            label: __('day(s)'),
        },
        {
            value: 'months',
            label: __('month(s)'),
        },
        {
            value: 'years',
            label: __('year(s)'),
        },
    ];

    const localizationStore = useLocalizationStore();
    const newFilenames = ref<String[]>([]);
    const newFiles = ref<Object[]>([]);
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sessionStore = useSessionStore();
    const showEditCreatedAt = ref<boolean>(false);
    const showEditSignedAt = ref<boolean>(false);
    const ticketRepository = TicketRepository.getInstance();
    const tooltipEndDate = ref<boolean>(false);
    const tooltipInventory = ref();
    const tooltipSignedAt = ref<boolean>(false);
    const tooltipStartDate = ref<boolean>(false);
    const worksheet = ref<object>();
    const worksheetId = route.params.worksheet;
    const worksheetRepository = WorksheetRepository.getInstance();

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            worksheet.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                worksheet_id: worksheet.value.id,
            });
        });

        computeWorksheetFieldValues();
    }

    function addProduct() {
        if (!addedProduct.value) {
            addedProductInvalid.value = true;

            return;
        }

        worksheet.value.inventory.forEach(product => {
            if (product.inventory_id === addedProduct.value.id) {
                addedProductInvalid.value = true;

                return;
            }
        });

        if (!addedProductInvalid.value) {
            worksheet.value.inventory.push({
                assignee_id: sessionStore.user.id,
                code: addedProduct.value.code,
                inventory_id: addedProduct.value.id,
                is_custom: 0,
                name: addedProduct.value.name,
                quantity: 1,
                sale_price: addedProduct.value.sale_price,
                um: addedProduct.value.um,
                worksheet_id: worksheet.value.id
            });

            addedProduct.value = null;
        }
    }

    function addSn() {
        worksheet.value.serial_numbers.push({
            has_been_serviced: 1,
            is_custom: 1,
            maintenance_performed: 1,
            name: addedSn.value.name,
            serial_number: addedSn.value,
            serial_number_id: addedSn.value.id,
            sn: addedSn.value.sn,
            usage: addedSn.value.current_usage,
            worksheet_id: worksheet.value.id
        });

        addedSn.value = null;
    }

    function addTask() {
        worksheet.value.tasks.push({
            assignee_id: sessionStore.user.id,
            completed_at: DateTime.now().toISO(),
            is_custom: 1,
            name: addedTask.value,
            worksheet_id: worksheet.value.id
        });

        addedTask.value = '';
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        worksheet.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computeWorksheetFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = worksheet.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        worksheet.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            worksheet_id: worksheet.value.id,
                        });
                    }
                });
            } else {
                const found = worksheet.value.fields.findIndex(f => {
                    return f.key === field.key;
                });
                
                if (found === -1) {
                    worksheet.value.fields.push({
                        key: field.key,
                        value: null,
                        worksheet_id: worksheet.value.id,
                    });
                }
            }
        });

        worksheet.value.fieldValues = worksheet.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            worksheet.value.fields = worksheet.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = worksheet.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                worksheet.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computeWorksheetFieldValues();
    }

    function deleteProduct(key) {
        worksheet.value.inventory.splice(key, 1);
    }

    function deleteSn(key) {
        worksheet.value.serial_numbers.splice(key, 1);
    }

    function deleteTask(key) {
        worksheet.value.tasks.splice(key, 1);
    }

    async function downloadFiles() {
        await worksheetRepository.downloadFiles(worksheet.value.id);
    }

    async function downloadTaskFiles(task) {
        await worksheetRepository.downloadTaskFiles(task);
    }

    async function employeeGet(employee) {
        if (isRef(employee) && employee.value) {
            return await employeeRepository.show(employee.value.id);
        } else if (employee && employee.id) {
            return await employeeRepository.show(employee.id);
        } else {
            return null;
        }
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                search: val
            }
        });
    }

    function deleteFile(key: number, isNew: boolean = false) {
        if (!isNew) {
            worksheet.value.files.splice(key, 1);
        } else {
            newFilenames.value.splice(key, 1);
            newFiles.value.splice(key, 1);
        }
    }

    async function getWorksheet() {
        worksheet.value = await worksheetRepository.show(worksheetId, {
            include: [
                'fields',
                'files',
                'inventory',
                'serialNumbers',
                'serialNumbers.serialNumber',
                'serialNumbers.serialNumber.partNumber',
                'tasks',
                'tasks.files',
                'ticket',
                'ticket.additionalAssignees',
                'ticket.assignee',
                'ticket.files',
                'ticket.site',
                'ticket.site.partner',
                'time_logs.assignee',
            ]
        });

        computeWorksheetFieldValues();
        parseWorksheetDates();
    }

    function parseWorksheetDates() {
        worksheet.value.created_at_date = DateTime.fromISO(worksheet.value.created_at).toJSDate();
        worksheet.value.created_at_time = {
            hours: DateTime.fromISO(worksheet.value.created_at).toFormat('HH:mm').split(':')[0],
            minutes: DateTime.fromISO(worksheet.value.created_at).toFormat('HH:mm').split(':')[1],
        };

        if (worksheet.value.signed_at) {
            worksheet.value.signed_at_date = DateTime.fromISO(worksheet.value.signed_at).toJSDate();
            worksheet.value.signed_at_time = {
                hours: DateTime.fromISO(worksheet.value.signed_at).toFormat('HH:mm').split(':')[0],
                minutes: DateTime.fromISO(worksheet.value.signed_at).toFormat('HH:mm').split(':')[1],
            };
        } else {
            worksheet.value.signed_at_date = DateTime.now().toJSDate();
            worksheet.value.signed_at_time = {
                hours: DateTime.now().toFormat('HH:mm').split(':')[0],
                minutes: DateTime.now().toFormat('HH:mm').split(':')[1],
            };
        }
    }

    async function productGet(product) {
        if (isRef(product) && product.value) {
            return await inventoryRepository.show(product.value.id);
        } else if (product && product.id) {
            return await inventoryRepository.show(product.id);
        } else {
            return null;
        }
    }

    async function productSearch(val) {
        return await inventoryRepository.index({
            filters : {
                search: val
            }
        });
    }

    async function snGet(sn) {
        if (isRef(sn) && sn.value) {
            return worksheet.value.serial_numbers.find((serialNumber) => serialNumber.id === sn.value.id)
        } else if (sn && sn.id) {
            return worksheet.value.serial_numbers.find((serialNumber) => serialNumber.id === sn.id)
        } else {
            return null;
        }
    }

    async function snSearch(val) {
        return await serialNumberRepository.index({
            filters : {
                search: val
            }
        });
    }

    async function submit() {
        await ticketRepository.update(worksheet.value.ticket_id, {
            assignee_id: worksheet.value.ticket.assignee_id,
            additional_assignees: worksheet.value.ticket.additional_assignees,
            schedule_start_date: worksheet.value.ticket.schedule_start_date,
            schedule_start_time: worksheet.value.ticket.schedule_start_time
                ? {
                    hours: worksheet.value.ticket.schedule_start_time.split(':')[0],
                    minutes: worksheet.value.ticket.schedule_start_time.split(':')[1],
                }
                : null,
            schedule_end_date: worksheet.value.ticket.schedule_end_date,
            schedule_end_time: worksheet.value.ticket.schedule_end_time
                ? {
                    hours: worksheet.value.ticket.schedule_end_time.split(':')[0],
                    minutes: worksheet.value.ticket.schedule_end_time.split(':')[1],
                }
                : null,
        });

        worksheet.value.newFilenames = newFilenames.value;
        worksheet.value.newFiles = newFiles.value;
        worksheet.value.self_signed = true;

        worksheet.value = await worksheetRepository.update(worksheet.value.id, worksheet.value, {
            include: [
                'fields',
                'files',
                'inventory',
                'serialNumbers',
                'serialNumbers.serialNumber',
                'serialNumbers.serialNumber.partNumber',
                'tasks',
                'tasks.files',
                'ticket',
                'ticket.additionalAssignees',
                'ticket.assignee',
                'ticket.files',
                'ticket.site',
                'ticket.site.partner',
                'time_logs.assignee',
            ],
            tz: DateTime.now().zoneName,
        });

        computeWorksheetFieldValues();
        parseWorksheetDates();

        newFilenames.value = [];
        newFiles.value = [];
    }

    function updateWorksheetTimes() {
        worksheet.value.created_at = DateTime.fromJSDate(worksheet.value.created_at_date).set({
            hour: parseInt(worksheet.value.created_at_time.hours),
            minute: parseInt(worksheet.value.created_at_time.minutes),
        }).toISO();

        worksheet.value.signed_at = DateTime.fromJSDate(worksheet.value.signed_at_date).set({
            hour: parseInt(worksheet.value.signed_at_time.hours),
            minute: parseInt(worksheet.value.signed_at_time.minutes),
        }).toISO();
    }

    function upload() {
        document.getElementById('worksheet.files').click();
    }

    onMounted(async () => {
        getWorksheet();

        await requestStore.get('worksheets/fields').then(async (response : any) => {
            fields.value = response.data;
        });
    });

    watch(() => worksheet.value, () => {
        worksheet.value.ticket.assignee_id = worksheet.value.ticket.assignee?.id;

        Object.keys(worksheet.value.fieldValues).forEach(key => {
            let field = worksheet.value.fields.find(f => f.key === key);

            if (field) {
                field.value = worksheet.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
